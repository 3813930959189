import React, { useRef, useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { useCollectionData } from 'react-firebase-hooks/firestore';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, addDoc, FieldValue, query, orderBy, limit, serverTimestamp } from 'firebase/firestore';
//import { getAuth, signInWithPopup, GoogleAuthProvider, debugErrorMap } from 'firebase/auth';

import toast, { Toaster } from 'react-hot-toast';

import CookieConsent from "react-cookie-consent";

import SearchResult from './components/SearchResult';
import DisplayTeamMembers from './components/DisplayTeamMembers';
import Timeline from './components/Timeline';
import TimelineItem from './components/TimelineItem';
import Article from './components/Article';

const notifySubscribed = () => toast('Thank you for subscribing.',{ icon: '🙏'});

const notifyMessageReceived = () => toast('Thank you for reaching out. We will respond as soon as possible',{ icon: '📧'});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6a3t7EuMoM-tFd5ohQ7REeDR4yk3KkkU",
  authDomain: "tpx-global.firebaseapp.com",
  projectId: "tpx-global",
  storageBucket: "tpx-global.appspot.com",
  messagingSenderId: "888600666183",
  appId: "1:888600666183:web:da64b4d1829ef868571162",
  measurementId: "G-QCGFLRX7VX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

var knowledgeData = {
"levels":[
  {"title": "The title 1", "description": "The description A"},
  {"title": "The title 2", "description": "The description B"},
  {"title": "The title 3", "description": "The description C"},
  {"title": "The title 4", "description": "The description D"}
]
};


// Main app. Load the site data and display.
function App() {
  const [searchText, setSearchText] = useState("");
  const [siteContent, setSiteContent] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredReferences, setFilteredReferences] = useState([]);

  const [references, setReferences] = useState([
      {
        id: 1,
        keywords: "founder",
        title: "The founders include Antony and David.",
        description: "Click to find out more information about the founders",
        imageURL: "",
        linkURL: "index.html#team"
      },
      {
        id: 2,
        keywords: "coiner",
        title: "What is a Coiner?",
        description: "A Coiner acts with complete control of their own property and continued full ownership. A Coiner is willing to share a slice of their property with multiple individuals on the Exchange. It may be 1%, it may be 10%, it may be 49% but you retain control. By having a 24/7 relationship with the Exchange, once fully approved, the Coiner is able to create a liquid asset immediately.",
        imageURL: "assets/images/concepts/Coiners.svg",
        linkURL: "coiners.html"
      },
      {
        id: 3,
        keywords: "stacker",
        title: "What is a stacker?",
        description: "A Stacker wishes to buy a slice of a Coined property to trade on the Exchange safely. A Stacker may simply wish to buy a Slice to own a percentage of property as an asset for a specific period. A Stacker may also be a potential Coiner who is testing the process before Coining their own property.",
        imageURL: "assets/images/concepts/Stackers.svg",
        linkURL: "stackers.html"
      },
      {
        id: 5,
        keywords: "hm,land,registry",
        title: "HM Land Registry",
        description: "The HM Land Registry in a non-ministerial department of the UK government created in 1862. It registers approximately 88% of the ownership of land and property in England and Wales recording rights of freehold properties and leaseholds where the lease has been granted for a term exceeding 7 years and is partly funded by registration and search fees. The Registry provides access to its database of titles and ownership, charges and plans. This online registration simplifies conveyancing and makes transactions easier and less costly. Owners of property not registered can make voluntary applications for registration.",
        imageURL: "",
        linkURL: ""
      },
      {
        id: 6,
        keywords: "new,rule,law,digital,assets",
        title: "New Rule of Law for Digital Assets",
        description: "It illustrates the perennial link between the essential importance of property rights protection and economic growth.",
        imageURL: "",
        linkURL: ""
      },
      {
        id: 7,
        keywords: "property,disruption",
        title: "Property disruption",
        description: "Billions tied up in property that would work more flexibly for everyone involved",
        imageURL: "",
        linkURL: ""
      },
      {
        id: 8,
        keywords: "sharing,freehold",
        title: "Sharing the freehold",
        description: "It is a relationship with the exchange, in the same way you have a relationship with your banking app. 24/7. At your fingertips",
        imageURL: "",
        linkURL: ""
      }
    ]);
  

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [contactFullName, setContactFullName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactDetails, setContactDetails] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const fetchSiteContentData = async () => {
      const siteContentRef = doc(db, "siteContent", "landingPage");
      const siteResponse = await getDoc(siteContentRef);

      if (siteResponse.exists()) {
        setSiteContent(siteResponse.data());
      }
    }

    // declare the data fetching function
    const fetchReferencesContentData = async () => {
      const querySnapshot = await getDocs(collection(db, "references"));
      var newReferences = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        newReferences.push(doc.data());
      });

      if (newReferences.length > 0) {
        setReferences(newReferences);
      }
    }

    // call the request function
    fetchSiteContentData()
      // make sure to catch any error
      .catch(console.error);

    // call the request function
    fetchReferencesContentData()
      // make sure to catch any error
      .catch(console.error);

    }, []);

    // Search the site
    async function searchSite(searchFilter)
    {
      setSearchText(searchFilter);

      var searchResults = references.filter(ref => containsAny(ref.keywords, searchFilter));

      setFilteredReferences(searchResults);
    }

    function containsAny(content, filter)
    {
      if (filter.length <=0)
        return false;
      
      var terms = filter.toLowerCase().split(' ');

      if (terms.length == 1)
      {
        //console.log(" = one item only: " + terms + " in " + content+ " is " + content.includes(terms[0]));
        return content.includes(terms[0]);
      }

      var allMatched = true;

      terms.forEach(element => {
        //console.log("Checking: " + element);
        var item = element.trim();
        if (item.length > 0)
        {
          //console.log("  searching: " + content + " for " + item);
          if (!content.includes(item))
          {
            //console.log("  = missing " + item);
            allMatched = false;
          }
          //else
            //console.log("  = found " + item);
        }
      });

      return allMatched;
    }

    // Subscribe to an email list
    async function subscribeToEmail(list, name, email)
    {
      // Add a new document in collection list
      const docRef = await addDoc(collection(db, list), {
        name: name,
        email: email
      });

      setFullName("");
      setEmail("");

      notifySubscribed();
    }

    // Contact Us Request
    async function contactUs(contactfullName, contactEmail, contactSubject, contactDetails)
    {
      // Add a new document in collection list
      const docRef = await addDoc(collection(db, "\generalEnquiries"), {
        name: contactfullName,
        email: contactEmail,
        subject: contactSubject,
        details: contactDetails
      });

      setContactFullName("");
      setContactEmail("");
      setContactSubject("");
      setContactDetails("");

      notifyMessageReceived();
    }
    

  return (
    <Router>
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />

      {/* Loader Overlay Start */}
      <div id="loader-overlay">
        <div className="loader">
          <img src="assets/images/loader.svg" width="80" alt=""/>
        </div>
      </div>
      {/* Loader Overlay End */}

      {/* Loader Start */}
      <div className="wrapper">

        {/* Header Start */}
        <nav className="navbar navbar-default navbar-fixed navbar-transparent white bootsnav on no-full no-border">
          {/* Start Top Search */}
          <div className="fullscreen-search-overlay" id="search-overlay" Style="height:100%;margin-top:0px;padding-top:0px;">
            <a href="#" className="fullscreen-close"
              id="fullscreen-close-button"><i className="icofont icofont-close"></i></a>
            <div id="fullscreen-search-wrapper">
              <div id="fullscreen-searchform">
                <input name="siteSearch" type="text" value={searchText} placeholder="Type and hit Enter..." id="fullscreen-search-input"
                  className="search-bar-top" onChange={e => searchSite(e.target.value)} />
                <i className="fullscreen-search-icon icofont icofont-search">
                </i>
                <ul  class="search-results">
                  {filteredReferences && filteredReferences.map((ref,index) => <SearchResult index={index} key={index + '-'} result={ref} />)}
                </ul>
              </div>
            </div>
          </div>
          {/* End Top Search */}

          <div className="container">
            {/* Start Atribute Navigation */}
            <div className="attr-nav hidden-xs sm-display-none">
              <ul>
                <li className="side-menu"><a href="#"><i className="icofont icofont-navigation-menu"></i></a></li>
                <li className="search"><a href="#" id="search-button"><i className="icofont icofont-search"></i></a></li>
              </ul>
            </div>
            {/* End Atribute Navigation */}

            {/* Start Header Navigation */}
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu"> <i
                  className="tr-icon ion-android-menu"></i> </button>
              <div className="logo"> <a href="index.html"> <img className="logo logo-display" src="assets/images/logo/tpx-logo-light.svg"
                    alt=""/> <img className="logo logo-scrolled" src="assets/images/logo/tpx-logo-dark-grid.svg" alt=""/> </a> </div>
            </div>
            {/* End Header Navigation */}

            {/* Collect the nav links, forms, and other content for toggling */}
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="nav navbar-nav navbar-center" data-in="fadeIn" data-out="fadeOut">
                <li><a className="page-scroll" href="#buyers">Listing</a></li>
                <li><a className="page-scroll" href="#sellers">Buyers/Sellers</a></li>
                <li><a className="page-scroll" href="#team">Team</a></li>
                <li><a className="page-scroll" href="#press">Press</a></li>
                <li><a className="page-scroll" href="#contact">Contact</a></li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>

          {/* Floating Socials Start */}
          <div className="sticky-socials-container">
            <ul className="sticky">
            <a href="https://www.youtube.com/channel/UCDpcVw3_UGV_dVlk_ru5HWQ" target="_blank">
              <li>
                <i className="icofont icofont-social-linkedin"></i>
                <p><a href="#" target="_blank">Follow Us on<br/>LinkedIn</a></p>
              </li>
              </a>
              <a href="https://www.youtube.com/channel/UCDpcVw3_UGV_dVlk_ru5HWQ" target="_blank">
              <li>
                <i className="icofont icofont-social-twitter"></i>
                <p><a href="#" target="_blank">Follow Us on<br/>Twitter</a></p>
              </li>
              </a>
              <a href="https://www.youtube.com/channel/UCDpcVw3_UGV_dVlk_ru5HWQ" target="_blank">
              <li>
                <i className="icofont icofont-social-facebook"></i>
                <p><a href="#" target="_blank">Like Us on<br/>Facebook</a></p>
              </li>
              </a>
              <li>
                <i className="icofont icofont-social-instagram"></i>
                <p><a href="#" target="_blank">Follow Us on<br/>Instagram</a></p>
              </li>
            </ul>
          </div>
          {/* Floating Socials End */}

          {/* Start Side Menu */}
          <div className="side dark-bg">
            <a href="index.html" className="logo-side"><img src="assets/images/logo/tpx-logo-light.svg" alt="side-logo" /></a>
            <a href="#" className="close-side"><i className="icofont icofont-close"></i></a>
            <div className="widget mt-120">
              <ul className="link">
                <li className="link-item"><a className="page-scroll" href="#sellers">{ (siteContent !== null) ? siteContent.topMenu.menuB : "Unknown" }</a></li>
                <li className="link-item"><a className="page-scroll" href="#sellers">Sellers</a></li>
                <li className="link-item"><a className="page-scroll" href="#team">Team</a></li>
                <li className="link-item"><a className="page-scroll" href="#press">Press</a></li>
                <li className="link-item"><a className="page-scroll" href="#contact">Contact</a></li>
              </ul>
            </div>
            <ul className="social-media">
              <li><a href="#return-to-top" aria-label="LinkedIn" className="icofont icofont-social-linkedin" aria-hidden="true"> </a></li>
              <li><a href="#return-to-top" aria-label="Twitter" className="icofont icofont-social-twitter" aria-hidden="true"> </a></li>

              <li><a href="#return-to-top" aria-label="Facebook" className="icofont icofont-social-facebook" aria-hidden="true"> </a></li>
              <li><a href="#return-to-top" aria-label="Instagram" className="icofont icofont-social-instagram" aria-hidden="true"> </a></li>
            </ul>
          </div>
          {/* End Side Menu */}

        </nav>
        {/*  Header End */}

        {/* Video Start */}
        <section class="parallax-bg fixed-bg"
          data-parallax-bg-image={ (siteContent !== null) ? siteContent.parallaxSection.imageURL : "/assets/images/background/senglea-and-grand-harbor-malta-parallax-bg.jpg" }
          data-parallax-speed="0.5" data-parallax-direction="up">
          <div class="overlay-bg"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-8 text-center parallax-content height-400px centerize-col">
                <div class="center-layout">
                  <div class="v-align-middle">
                    <h1 class="font-400 white-color play-font">{ (siteContent !== null) ? siteContent.parallaxSection.title : "Own a part of something special" }</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Video End */}

          
      {/* Sign Up */}
      <section class="dark-bg" id="general-signup">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="section-title white-color">
                <h2 class="play-font">Want to know more?</h2>
                <h1 class="roboto-font text-uppercase">Become a Coiner</h1>
                <hr class="center_line white-bg"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-sm-2 col-xs-12 mt-40 mb-30 feature-box text-center"></div>
            <div class="col-md-8 col-sm-4 col-xs-12 mt-40 mb-30 feature-box text-center"
              Style="border: 2px solid rgb(255, 255, 255);padding-top:20px;padding-bottom:20px;">

              <div class="gradient-bg-icon mb-20">
                <i class="icofont icofont-email font-30px dark-color"></i>
              </div>
              <h3 class="mt-10 white-color">Sign Up to become a Coiner</h3>
              <p class="font-400 white-color">To get insights, information and more.</p>

              <div class="contact-form-style-02">
                <div class="messages"></div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="sr-only" for="name">Name</label>
                      <input type="text" name="name" class="md-input style-02" value={fullName} onChange={e => setFullName(e.target.value)}
                        Style="margin-left:10%;margin-right:10%;width:80%" id="name" placeholder="Name *" required
                        data-error="Your Name is Required"/>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="sr-only" for="email">Email</label>
                      <input type="email" name="email" class="md-input style-02" value={email} onChange={e => setEmail(e.target.value)}
                        Style="margin-left:10%;margin-right:10%;width:80%" id="email" placeholder="Email *" required
                        data-error="Please Enter Valid Email"/>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="text-center mt-20">
                      <button onClick={() => subscribeToEmail("\generalSubscribers", fullName, email)}
                        class="btn btn-lg btn-light-outline btn-square remove-margin">Subscribe for Updates</button>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="text-center mt-20">
                      <span>We hate SPAM as much as you do. Check our <a target="_blank" href="/policy.html"  Style="color:rgb(55, 141, 202);">privacy
                          policy</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 mt-40 mb-30 feature-box text-center"></div>
          </div>
        </div>
      </section>

        {/* Partners Start */}
        <div class="dark-bg pt-120 pb-120">
          <div class="container">
            <div class="row">
              <div class="client-slider slick">
              <div class="client-logo"> 
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-1.svg" height="10" Style="padding:25px;" alt="Concept 1"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-2.svg" height="10" Style="padding:25px;" alt="Concept 2"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-3.svg" height="10" Style="padding:25px;"  alt="Concept 3"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-4.svg" height="10" Style="padding:25px;" alt="Concept 4"/>
                </div>
                <div class="client-logo"> 
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-1.svg" height="10" Style="padding:25px;" alt="Concept 1"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-2.svg" height="10" Style="padding:25px;" alt="Concept 2"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-3.svg" height="10" Style="padding:25px;"  alt="Concept 3"/>
                </div>
                <div class="client-logo">
                  <img class="img-responsive" src="assets/images/clients/tpx-concept-4.svg" height="10" Style="padding:25px;" alt="Concept 4"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Partners End */}

        <DisplayTeamMembers/>

        <DisplayArticles/>

        {/* Contact start */}
        <section class="dark-bg pt-0 pb-0 transition-none" id="contact">
          <div class="col-md-6 col-sm-4 bg-flex bg-flex-right">
            <div class="bg-flex-holder bg-flex-cover">
              <div id="map-style-2" class="wide"></div>
            </div>
          </div>
          <div class="container">
            <div class="col-md-5 col-sm-7 pt-120 pb-120 xs-pt-20 xs-pb-80">

              <div name="contact-form" id="contact-form" action="php/contact.php" method="POST"
                class="contact-form-style-02">
                <div class="messages"></div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label class="sr-only" for="name">Name</label>
                      <input type="text" name="name" class="md-input style-02" id="name" placeholder="Name *" required value={contactFullName} onChange={e => setContactFullName(e.target.value)}
                        data-error="Your Name is Required"/>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label class="sr-only" for="email">Email</label>
                      <input type="email" name="email" class="md-input style-02" id="email" placeholder="Email *" required value={contactEmail} onChange={e => setContactEmail(e.target.value)}
                        data-error="Please Enter Valid Email"/>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="sr-only" for="subject">Subject</label>
                      <input type="text" name="subject" class="md-input style-02" id="subject" placeholder="Subject" value={contactSubject} onChange={e => setContactSubject(e.target.value)}/>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="sr-only" for="message">Details</label>
                      <textarea name="message" class="md-textarea style-02" id="message" rows="7" value={contactDetails} onChange={e => setContactDetails(e.target.value)}
                        placeholder="Details" required data-error="Please, Leave us a message"></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="text-left mt-20">
                      <button class="btn btn-lg btn-light-outline btn-square remove-margin" onClick={() => contactUs(contactFullName, contactEmail, contactSubject, contactDetails)}>Send
                        Message </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact end */}

        {/* Footer start */}
        <footer className="footer" id="contact">
          <div className="footer-main">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="widget widget-text">
                    <div className="logo-footer"><a href="index.html"> <img className="img-responsive"
                          src="assets/images/logo/tpx-logo-dark-grid.svg" Style="height: 4em;" alt="TPX Logo"/></a> </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-2">
                  <div className="widget widget-links">
                    <h5 className="widget-title">Social Media</h5>
                    <ul>
                      <li><a target="_blank" href={ (siteContent !== null) ? siteContent.footerSection.linkedIn : "https://www.linkedin.com/company/tpxexchange/mycompany/" }><span className="icofont icofont-social-linkedin">&nbsp;</span>Linkedin</a></li>
                      <li><a target="_blank" href={ (siteContent !== null) ? siteContent.footerSection.twitter : "https://twitter.com/tpxexchanges?lang=en-GB" }><span className="icofont icofont-social-twitter">&nbsp;</span>Twitter</a></li>
                      <li><a target="_blank" href={ (siteContent !== null) ? siteContent.footerSection.facebook : "ttps://www.facebook.com/TPX-Property-Exchanges-109715275224154" }><span className="icofont icofont-social-facebook">&nbsp;</span>Facebook</a></li>
                      <li><a target="_blank" href={ (siteContent !== null) ? siteContent.footerSection.instagram : "#" }><span className="icofont icofont-social-instagram">&nbsp;</span>Instagram</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-2">
                  <div className="widget widget-links">
                    <h5 className="widget-title">Quick Links</h5>
                    <ul>
                      <li><a href="#team">Team</a></li>
                      <li><a href="#contact">Contact Us</a></li>
                      <li><a href="/policy.html">Privacy Policy</a></li>
                      <li><a href="/terms.html">Terms &amp; Conditions</a></li>
                      <li><a href="/gdpr.html">GDPR</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-2">
                  <div className="widget widget-text widget-links">
                    <h5 className="widget-title">Contact Us</h5>
                    <ul>
                      <li> <i className="icofont icofont-social-google-map"></i> 50 Sloane Avenue, London, SW3 3DD</li>
                      <li> <i className="icofont icofont-smart-phone"></i> +44 (0)20 7459 4007</li>
                      <li> <i class="icofont icofont-email"></i> <a href="mailto:info@tpx-global.com">info@tpx-global.com</a> </li>
                      <li> <i class="icofont icofont-globe-alt"></i> <a href="#">www.tpx-global.com</a> </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="widget widget-text widget-links">
                    <h5 className="widget-title">Media &amp; Press</h5>
                    <ul>
                      <li>Flame PR is handling our press. Please contact either:</li>
                      <li><b>Emmaline Windeler</b></li>
                      <li> <i className="icofont icofont-smart-phone"></i> +44 (0)780 573 7887</li>
                      <li> <i class="icofont icofont-email"></i> <a href="mailto:emmaline@flamepr.com">emmaline@flamepr.com</a> </li>
                      <li><b>Oliver Traherne</b></li>
                      <li> <i className="icofont icofont-smart-phone"></i> +44 (0)791 773 6179</li>
                      <li> <i class="icofont icofont-email"></i> <a href="mailto:oliver@flamepr.com">oliver@flamepr.com</a> </li>
                      <li> <i class="icofont icofont-globe-alt"></i> <a href="http://flamepr.com/">www.flamepr.com/</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="copy-right text-center">
                    { (siteContent !== null) ? siteContent.footerSection.copyright : "© 2022 TPX Global Limited. All rights reserved." }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* Footer end */}
        
        {/* Goto to top link start */}
        <a href="javascript:" id="return-to-top"><i className="icofont icofont-arrow-up"></i></a>
        {/* Goto to top link end */}

        <CookieConsent
          location="bottom"
          buttonText="I Accept"
          cookieName="tpxGlobal"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.<br/>
          <span style={{ fontSize: "10px" }}>We respect your privacy and only use cookies to improve the effectiveness of the site.</span>
        </CookieConsent>
      </div>
    </>
    </Router>
  );
}

// Display all the blog articles and press.
function DisplayArticles() {
  const articlesRef = collection(db, 'articles');
  const q = query(articlesRef, orderBy('postDate'), limit(25));

  const [articles] = useCollectionData(q, { idField: 'id' });

  const [formValue, setFormValue] = useState('');

  return (<>
    <section class="white-bg" id="press">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="section-title">
                  <h2 class="play-font">Latest News</h2>
                  <h1 class="roboto-font text-uppercase">Press</h1>
                  <hr class="center_line dark-bg"/>
                </div>
              </div>
            </div>

            <div class="row mt-20">
              {articles && articles.map((article,index) => <Article index={index} key={index + '-' + article.uid} article={article} />)}
            </div>
          </div>
        </section>
  </>)
}



export default App;
