import React from 'react';

function Article(props) {
  const { text, uid, title, description, postDate, photoURL, photoAltText, articleURL, readTime, comments } = props.article;
  const index = props.index;

  return (<>
    <div key={index} class="col-md-4 col-sm-4 col-xs-12 article-space">
      <div class="post">
        <a href={articleURL}>
        <div class="post-img"> <img class="img-responsive" src={photoURL} alt={photoAltText} /> </div>
        </a>
        <div class="post-info all-padding-30">
          <h3><a href={articleURL}>{title}...</a></h3>
          <hr/>
          <p class="mt-10"> 
          { readTime==1 && 
            (
              <>
              <span> <a class="extras-wrap" href={articleURL}><i class="icofont icofont-chat"></i><span>0
                  Comments</span></a> <span class="extras-wrap"><i class="icofont icofont-clock-time"></i><span>{readTime}&nbsp;
                  Minutes</span></span> </span>
                  </>
            )}</p>
          <a class="readmore" href={articleURL}><span>Read More</span></a>
        </div>
      </div>
    </div>
  </>)
}

export default Article;
