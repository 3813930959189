import React from 'react';

function SearchResult(props) {
  const { refId, keywords, title, description, imageURL, linkURL } = props.result;
  const index = props.index;

  return (<>
    <div key={index} class="col-md-12 col-sm-12 col-xs-12 article-space search-item">
      { linkURL &&
        <a href={linkURL}>
        <div class="post">
          <div class="post-img col-md-2 col-sm-2 col-xs-hidden">
            { imageURL &&
              <img class="img-responsive" src={imageURL} alt={title} Style="padding:5px;"/>
            }
          </div>
          <div class="post-info all-padding-30 col-md-10 col-sm-10 col-xs-12 search-result">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
        </a>
      }
      { !linkURL &&
        <div class="post">
          <div class="post-img col-md-2 col-sm-2 col-xs-2">
            { imageURL &&
              <img class="img-responsive" src={imageURL} alt={title} />
            }
          </div>
          <div class="post-info all-padding-30 col-md-10 col-sm-10 col-xs-10 search-result">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      }
    </div>
  </>)
}

export default SearchResult;
