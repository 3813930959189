import React from 'react';

function TeamMember3Col(props) {
  const { name, description, role, linkedIn, facebook, twitter, youTube, imageURL } = props.teamMember;
  
  return (<>
    <div class="col-md-4 col-sm-12 col-xs-12 col-12 d-flex justify-content-center text-center" Style="margin-bottom: 20px;">
      <div class="team-member">
        <div class="team-thumb">
          <div class="thumb-overlay"></div>
          <img src={imageURL} alt={name}/>
          <div class="member-info text-center dark-bg">
            <h3>{ name ? name : ""}</h3>
            <span class="title">{ role ? role : ""}</span>
            <p>{ description ? description : ""}</p>
            <div class="social-icons-style-02">
              <ul class="sm-icon mt-20">
                {facebook &&
                  <li><a class="fb" href="{facebook}"><i class="icofont icofont-social-facebook"></i></a></li>
                }
                {twitter &&
                  <li><a class="tw" href="{twitter}"><i class="icofont icofont-social-twitter"></i></a></li>
                }
                {youTube &&
                  <li><a class="be" href="{youTube}"><i class="icofont icofont-social-youtube"></i></a></li>
                }
                {linkedIn &&
                  <li><a class="in" href={linkedIn}><i class="icofont icofont-social-linkedin"></i></a></li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default TeamMember3Col;
