import React from 'react';
import TeamMember2Col from './TeamMember2Col';
import TeamMember3Col from './TeamMember3Col';

// Static fallback data before loading
const defaultTeamMemberA = 
{
  name: "Antony Abell",
  role: "Chief Executive Officer",
  description: "Property Expert and Financier based in London. 250 years of international  family banking history. 25 years in tech.",
  facebook: "",
  twitter: "",
  youTube: "",
  linkedIn: "https://www.linkedin.com/in/antonyabell/",
  imageURL: "assets/images/team/team-antony.jpg"
};

const defaultTeamMemberC = 
{
  name: "Nigel Tobin",
  role: "Operations",
  description: "Multi-jurisdictional banking platform builder. Former Director of Hometrack (UK). Property finance expert.",
  facebook: "",
  twitter: "",
  youTube: "",
  linkedIn: "https://www.linkedin.com/in/nigel-tobin-0a68354/",
  imageURL: "assets/images/team/team-nigel.jpg"
};

const defaultTeamMemberD = 
{
  name: "Michael Webber",
  role: "Property Legal Advisor",
  description: "Barrister. Former chairman UK Mortgage Lenders Association and director of the UK Land Registry. Expert in UK Government property law.",
  facebook: "",
  twitter: "",
  youTube: "",
  linkedIn: "",
  imageURL: "assets/images/team/team-michael2.jpg"
};

const defaultTeamMemberE = 
{
  name: "Ian Taylor MBE",
  role: "Acting Chairman",
  description: "Former Government Minister for Science, Space and Technology. Previous Parliamentary Scientific Committee Chairman. Previous member of IPPR for National Security.",
  facebook: "",
  twitter: "",
  youTube: "",
  linkedIn: "https://www.linkedin.com/in/iancolintaylorlinkedin",
  imageURL: "assets/images/team/team-ian.jpg"
};

var foundersTeam = [defaultTeamMemberA];
var managementTeam = [defaultTeamMemberC, defaultTeamMemberD, defaultTeamMemberE];

// Display all the team members
function DisplayTeamMembers(props) {
  return (<>
    <section class="grey-bg" id="team">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="section-title">
              <h2 class="play-font">Connect with the</h2>
              <h1 class="roboto-font text-uppercase">TPX™ Leadership Team</h1>
              <hr class="center_line dark-bg"/>
            </div>
          </div>
        </div>

        <h2>Founder</h2>

        <div class="row mt-50">
            {foundersTeam && foundersTeam.map((teamMemberDetails,index) => <TeamMember2Col key={index + '-teamMember'} teamMember={teamMemberDetails} />) }
        </div>

        <h2>Leadership</h2>

        <div class="row mt-50">
            {managementTeam && managementTeam.map((teamMemberDetails,index) => <TeamMember3Col key={index + '-teamMember'} teamMember={teamMemberDetails} />) }
        </div>
      </div>
    </section>
  </>)
}

export default DisplayTeamMembers;